define("discourse/plugins/discourse-calendar/discourse/components/discourse-post-event/invitees", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse-common/helpers/i18n", "discourse/plugins/discourse-calendar/discourse/components/modal/post-event-invitees", "discourse/plugins/discourse-calendar/discourse/components/discourse-post-event/invitee", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _i18n, _postEventInvitees, _invitee, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscoursePostEventInvitees extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    showAllInvitees() {
      this.modal.show(_postEventInvitees.default, {
        model: {
          event: this.args.event,
          title: this.args.event.title,
          extraClass: this.args.event.extraClass
        }
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "showAllInvitees", [_object.action]))();
    get statsInfo() {
      const stats1 = [];
      const visibleStats1 = this.siteSettings.event_participation_buttons.split("|").filter(Boolean);
      if (this.args.event.isPrivate) {
        visibleStats1.push("invited");
      }
      visibleStats1.forEach(button1 => {
        const localeKey1 = button1.replace(" ", "_");
        if (button1 === "not_going") {
          button1 = "notGoing";
        }
        const count1 = this.args.event.stats[button1] || 0;
        const label1 = (0, _i18n.default)(`discourse_calendar.discourse_post_event.models.invitee.status.${localeKey1}_count`, {
          count: count1
        });
        stats1.push({
          class: `event-status-${localeKey1}`,
          label: label1
        });
      });
      return stats1;
    }
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#unless @event.minimal}}
          {{#if @event.shouldDisplayInvitees}}
            <section class="event__section event-invitees">
              <div class="header">
                <div class="event-invitees-status">
                  {{#each this.statsInfo as |info|}}
                    <span class={{info.class}}>{{info.label}}</span>
                  {{/each}}
                </div>
    
                <DButton
                  class="show-all btn-small"
                  @label="discourse_calendar.discourse_post_event.event_ui.show_all"
                  @action={{this.showAllInvitees}}
                />
              </div>
              <ul class="event-invitees-avatars">
                {{#each @event.sampleInvitees as |invitee|}}
                  <Invitee @invitee={{invitee}} />
                {{/each}}
              </ul>
            </section>
          {{/if}}
        {{/unless}}
      
    */
    {
      "id": "wK9P9WQk",
      "block": "[[[1,\"\\n\"],[41,[51,[30,1,[\"minimal\"]]],[[[41,[30,1,[\"shouldDisplayInvitees\"]],[[[1,\"        \"],[10,\"section\"],[14,0,\"event__section event-invitees\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"header\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"event-invitees-status\"],[12],[1,\"\\n\"],[42,[28,[31,3],[[28,[31,3],[[30,0,[\"statsInfo\"]]],null]],null],null,[[[1,\"                \"],[10,1],[15,0,[30,2,[\"class\"]]],[12],[1,[30,2,[\"label\"]]],[13],[1,\"\\n\"]],[2]],null],[1,\"            \"],[13],[1,\"\\n\\n            \"],[8,[32,0],[[24,0,\"show-all btn-small\"]],[[\"@label\",\"@action\"],[\"discourse_calendar.discourse_post_event.event_ui.show_all\",[30,0,[\"showAllInvitees\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,\"ul\"],[14,0,\"event-invitees-avatars\"],[12],[1,\"\\n\"],[42,[28,[31,3],[[28,[31,3],[[30,1,[\"sampleInvitees\"]]],null]],null],null,[[[1,\"              \"],[8,[32,1],null,[[\"@invitee\"],[[30,3]]],null],[1,\"\\n\"]],[3]],null],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"  \"]],[\"@event\",\"info\",\"invitee\"],false,[\"unless\",\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-calendar/discourse/components/discourse-post-event/invitees.js",
      "scope": () => [_dButton.default, _invitee.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = DiscoursePostEventInvitees;
});