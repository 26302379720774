define("discourse/plugins/discourse-calendar/discourse/components/discourse-post-event/event-status", ["exports", "@glimmer/component", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EventStatus extends _component.default {
    get eventStatusLabel() {
      return (0, _i18n.default)(`discourse_calendar.discourse_post_event.models.event.status.${this.args.event.status}.title`);
    }
    get eventStatusDescription() {
      return (0, _i18n.default)(`discourse_calendar.discourse_post_event.models.event.status.${this.args.event.status}.description`);
    }
    get statusClass() {
      return `status ${this.args.event.status}`;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @event.isExpired}}
          <span class="status expired">
            {{i18n "discourse_calendar.discourse_post_event.models.event.expired"}}
          </span>
        {{else if @event.isClosed}}
          <span class="status closed">
            {{i18n "discourse_calendar.discourse_post_event.models.event.closed"}}
          </span>
        {{else}}
          <span class={{this.statusClass}} title={{this.eventStatusDescription}}>
            {{this.eventStatusLabel}}
          </span>
        {{/if}}
      
    */
    {
      "id": "Ju+vYCLK",
      "block": "[[[1,\"\\n\"],[41,[30,1,[\"isExpired\"]],[[[1,\"      \"],[10,1],[14,0,\"status expired\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"discourse_calendar.discourse_post_event.models.event.expired\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[41,[30,1,[\"isClosed\"]],[[[1,\"      \"],[10,1],[14,0,\"status closed\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"discourse_calendar.discourse_post_event.models.event.closed\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,1],[15,0,[30,0,[\"statusClass\"]]],[15,\"title\",[30,0,[\"eventStatusDescription\"]]],[12],[1,\"\\n        \"],[1,[30,0,[\"eventStatusLabel\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]],[]]],[1,\"  \"]],[\"@event\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-calendar/discourse/components/discourse-post-event/event-status.js",
      "scope": () => [_i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = EventStatus;
});