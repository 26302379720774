define("discourse/plugins/discourse-calendar/discourse/components/upcoming-events-calendar", ["exports", "@ember/component", "@ember/runloop", "rsvp", "discourse/lib/load-script", "discourse/models/category", "discourse-common/lib/get-url", "discourse/plugins/discourse-calendar/discourse/helpers/format-event-name", "discourse/plugins/discourse-calendar/discourse/lib/add-recurrent-events", "discourse/plugins/discourse-calendar/discourse/lib/full-calendar-default-options", "discourse/plugins/discourse-calendar/discourse/lib/guess-best-date-format", "@ember/template-factory"], function (_exports, _component, _runloop, _rsvp, _loadScript, _category, _getUrl, _formatEventName, _addRecurrentEvents, _fullCalendarDefaultOptions, _guessBestDateFormat, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div id="upcoming-events-calendar"></div>
  */
  {
    "id": "unERWDqE",
    "block": "[[[10,0],[14,1,\"upcoming-events-calendar\"],[12],[13]],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-calendar/discourse/components/upcoming-events-calendar.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    tagName: "",
    events: null,
    init() {
      this._super(...arguments);
      this._calendar = null;
    },
    willDestroyElement() {
      this._super(...arguments);
      this._calendar && this._calendar.destroy();
      this._calendar = null;
    },
    didInsertElement() {
      this._super(...arguments);
      this._renderCalendar();
    },
    _renderCalendar() {
      const siteSettings = this.site.siteSettings;
      const calendarNode = document.getElementById("upcoming-events-calendar");
      if (!calendarNode) {
        return;
      }
      calendarNode.innerHTML = "";
      this._loadCalendar().then(() => {
        const fullCalendar = new window.FullCalendar.Calendar(calendarNode, {
          ...(0, _fullCalendarDefaultOptions.default)(),
          eventPositioned: info => {
            if (siteSettings.events_max_rows === 0) {
              return;
            }
            let fcContent = info.el.querySelector(".fc-content");
            let computedStyle = window.getComputedStyle(fcContent);
            let lineHeight = parseInt(computedStyle.lineHeight, 10);
            if (lineHeight === 0) {
              lineHeight = 20;
            }
            let maxHeight = lineHeight * siteSettings.events_max_rows;
            if (fcContent) {
              fcContent.style.maxHeight = `${maxHeight}px`;
            }
            let fcTitle = info.el.querySelector(".fc-title");
            if (fcTitle) {
              fcTitle.style.overflow = "hidden";
              fcTitle.style.whiteSpace = "pre-wrap";
            }
            fullCalendar.updateSize();
          }
        });
        this._calendar = fullCalendar;
        const tagsColorsMap = JSON.parse(siteSettings.map_events_to_color);
        const originalEventAndRecurrents = (0, _addRecurrentEvents.default)(this.events);
        (originalEventAndRecurrents || []).forEach(event => {
          const {
            startsAt,
            endsAt,
            post,
            categoryId
          } = event;
          let backgroundColor;
          if (post.topic.tags) {
            const tagColorEntry = tagsColorsMap.find(entry => entry.type === "tag" && post.topic.tags.includes(entry.slug));
            backgroundColor = tagColorEntry?.color;
          }
          if (!backgroundColor) {
            const categoryColorEntry = tagsColorsMap.find(entry => entry.type === "category" && entry.slug === post.topic.category_slug);
            backgroundColor = categoryColorEntry?.color;
          }
          const categoryColor = _category.default.findById(categoryId)?.color;
          if (!backgroundColor && categoryColor) {
            backgroundColor = `#${categoryColor}`;
          }
          let classNames;
          if (moment(endsAt || startsAt).isBefore(moment())) {
            classNames = "fc-past-event";
          }
          this._calendar.addEvent({
            title: (0, _formatEventName.formatEventName)(event),
            start: startsAt,
            end: endsAt || startsAt,
            allDay: !(0, _guessBestDateFormat.isNotFullDayEvent)(moment(startsAt), moment(endsAt)),
            url: (0, _getUrl.default)(`/t/-/${post.topic.id}/${post.post_number}`),
            backgroundColor,
            classNames
          });
        });
        this._calendar.render();
      });
    },
    _loadCalendar() {
      return new _rsvp.Promise(resolve => {
        (0, _loadScript.default)("/plugins/discourse-calendar/javascripts/fullcalendar-with-moment-timezone.min.js").then(() => {
          (0, _runloop.schedule)("afterRender", () => {
            if (this.isDestroying || this.isDestroyed) {
              return;
            }
            resolve();
          });
        });
      });
    }
  }));
});